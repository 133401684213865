/*
 * ----------------------------------------
 * Brian McCracken Tesla Demo
 * ----------------------------------------
 */
* {
  font-family: "Helvetica Neue", sans-serif;
  color: #393c41;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
/*
 * ----------------------------------------
 * Brian McCracken Tesla Demo - animation bouncy down arrow on homepage
 * ----------------------------------------
 */
@keyframes animateDown {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(5px);
  }

  60% {
    transform: translateY(3px);
  }
}

/*
 * ----------------------------------------
 * Brian McCracken Tesla Demo - animation slide-top for model name on homepage
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}
/*
 * ----------------------------------------
 * Brian McCracken Tesla Demo - animation text-focus-in for line under model name on homepage
 * ----------------------------------------
 */
 @-webkit-keyframes text-focus-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*
 * ----------------------------------------
 * Brian McCracken Tesla Demo - animation text-focus-in and right button movement on homepage
 * ----------------------------------------
 */
 @-webkit-keyframes rightButton {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
    margin-left: 160px;
  }
  60% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
    margin-left: 160px;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 0.65;
    margin-left: 8px;
  }
}
@keyframes rightButton {
  0% {
    opacity: 0;
    margin-left: 160px;
  }
  60% {
    opacity: 0;
    margin-left: 160px;
  }
  100% {
    opacity: 0.65;
    margin-left: 8;
  }
}
/*
 * ----------------------------------------
 * Brian McCracken Tesla Demo - animation text-focus-in and left button movement on homepage
 * ----------------------------------------
 */
 @-webkit-keyframes leftButton {
  0% {
    opacity: 0;
    margin-right: 10px;
  }
  60% {
    opacity: 0;
    margin-right: 10px;
  }
  100% {
    opacity: 0.65;
    margin-right: 8px;
  }
}
@keyframes leftButton {
  0% {
    opacity: 0;
    margin-right: 10px;
  }
  60% {
    opacity: 0;
    margin-right: 10px;
  }
  100% {
    opacity: 0.65;
    margin-right: 8;
  }
}